<template>
  <div class="input-group">
    <label :for="inputLabel">
      {{ label }}
      <span v-if="required" class="required"> *</span>
    </label>
    <select
      :id="inputLabel"
      v-model="handleInput"
      :data-testid="`${formatNameForTestid(name)}_select`"
      v-bind="$attrs"
      :class="[
        'select',
        'dropdown',
        errorClass,
        { 'placeholder-color': !modelValue },
      ]"
      :name="name"
      autocomplete="off"
      @blur="v && v.$touch()"
    >
      <option value="" disabled selected>
        {{ placeholder || $t('common.form.fields.option.placeholder') }}
      </option>
      <option
        v-for="item in filteredOptions"
        :key="item.id"
        :value="item.id"
        :selected="item.id === modelValue"
      >
        {{ filteredOptions.length > 0 && item[$i18n.locale as keyof Option] }}
      </option>
    </select>
    <span v-if="showErrorMessage" class="error">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { Validation } from '@vuelidate/core'
import { formatNameForTestid } from '@/utils/forms'

defineOptions({
  inheritAttrs: false,
})

interface Props {
  modelValue: string | number
  label?: string
  required?: boolean
  options: any[]
  placeholder?: string
  v?: Validation['ExtractRulesResults']
  name?: string
  sortAlphabetically?: boolean
}
interface Option {
  de: string
  en: string
  id: string
  pl: string
}
const { locale } = useI18n()
const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: string | number): void
}>()
const { handleInput, errorMessage, errorClass, showErrorMessage, inputLabel } =
  useInput(props, emit)

const filteredOptions = ref<Option[]>([])
const seedFilteredOptions = () => {
  filteredOptions.value = props.options.filter(
    (item) => item[locale.value as keyof Option],
  )

  if (props.sortAlphabetically) {
    filteredOptions.value = filteredOptions.value
      .sort((a, b) =>
        a[locale.value as keyof Option].localeCompare(
          b[locale.value as keyof Option],
        ),
      )
      // Move United States to the top
      .sort((a, b) => {
        if (a.id === 'United States') {
          return -1
        }
        if (b.id === 'United States') {
          return 1
        }
        return 0
      })
  }
}
onMounted(() => {
  const form =
    (document.querySelector('form') as HTMLElement) ||
    document.querySelector('.test-case-table-control-bar')
  const options = {
    rootNode: document.body,
    rootMargin: '0px',
    threshold: 0,
  }
  const observer = new IntersectionObserver((entries) => {
    const { isIntersecting } = entries[0]
    if (isIntersecting) {
      seedFilteredOptions()
      observer.disconnect()
    }
  }, options)

  observer.observe(form)
})
</script>

<style lang="scss" scoped>
.select {
  width: 100%;
}

.placeholder-color {
  color: var(--paper-500);
}
</style>
